<template>
  <v-card
    v-bind="$attrs"
    class="player playerBg py-10 px-12 py-sm-23 px-sm-25"
    style="z-index: 100;"
    :width="isScreen ? '408' : '300'"
    outlined
  >
    <v-card-text class="pa-0">
      <div class="d-flex">
        <p class="mb-0 font-normal playerTitle--text mb-10 mb-sm-16" v-if="!!callerNumber">
          <span class="pr-2">Caller number</span>
          <v-scroll-x-transition>
            <span :class="{'d-block pt-4': !isScreen}" class="playerNumber--text">{{ getCallerNumber }}</span>
          </v-scroll-x-transition>
        </p>
        
        <v-spacer/>
        
        <v-icon class="pa-0" @click="closePlayer">$closeIcon</v-icon>
      </div>
      
      <div class="d-flex mb-6">
        <v-icon
          v-if="!playing || paused"
          class="mr-6"
          @click="playPause"
          :disabled="!loaded"
        >$playIcon</v-icon>
        
        <v-icon
          v-else
          class="mr-6"
          @click="playPause"
          :disabled="!loaded"
        >$pauseIcon</v-icon>
        
        <v-icon
          class="mr-6"
          @click="stop"
          :disabled="!loaded"
        >$stopIcon</v-icon>
        
        <v-icon
          class="mr-6"
          @click="mute"
          :disabled="!loaded"
          v-if="isMuted === false"
        >$volumeOnIcon</v-icon>
        
        <v-icon
          v-else
          class="mr-6"
          @click="mute"
          :disabled="!loaded"
        >$volumeOffIcon</v-icon>
        
        <v-icon
          v-if="loaded"
          class="mr-6"
          @click="download"
        >$downloadIcon</v-icon>
        
        <v-icon
          v-else
          class="mr-6"
          @click="reload"
        >mdi-refresh</v-icon>
        
      </div>
      
      <v-slider @click="setPosition" dense height="15" v-model="percentage" hide-details></v-slider>
      
      <p class="font-normal playerTitle--text ma-0 mt-6">
        {{ currentTime }} / {{ duration }}
      </p>
      
      <audio id="player" ref="player" :src="file"></audio>
    </v-card-text>
  </v-card>
</template>

<script>
const formatTime = seconds => {
  return new Date(seconds * 1000).toISOString().substr(11, 8)
}

export default {
  name: 'AudioPlayer',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    file: {
      type: String,
      default: null
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    callerNumber: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    isMuted: false,
    loaded: false,
    playing: false,
    paused: false,
    percentage: 0,
    currentTime: '00:00:00',
    audioFile: null,
    totalDuration: 0,
  }),
  computed: {
    duration() {
      return this.audioFile ? formatTime(this.totalDuration) : ''
    },
    isScreen() {
      return this.$vuetify.breakpoint.width > 450
    },
    getCallerNumber() {
      return this.callerNumber ? `+${this.callerNumber}` : ''
    }
  },
  mounted() {
    this.audioFile = this.$refs.player;
    this.init()
  },
  methods: {
    setPosition() {
      this.audioFile.currentTime = parseInt(this.audioFile.duration / 100 * this.percentage)
    },
    playPause() {
      this.playing ? this.pause() : this.play()
    },
    stop() {
      this.paused = this.playing = false;
      this.audioFile.pause()
      this.audioFile.currentTime = 0
    },
    play() {
      if (this.playing) return
      this.paused = false
      this.playing = true
      this.audioFile.play()
    },
    pause() {
      this.paused = !this.paused
      this.paused ? this.audioFile.pause() : this.audioFile.play()
    },
    download() {
      this.audioFile.pause()
      window.open(this.file, 'download')
    },
    mute() {
      this.isMuted = !this.isMuted
      this.audioFile.muted = this.isMuted
    },
    reload() {
      this.audioFile.load()
    },
    _handleLoaded() {
      if (this.audioFile.readyState >= 2) {
        if (this.autoPlay) {
          this.play()
        }
        this.loaded = true;
        this.totalDuration = parseInt(this.audioFile.duration)
      } else {
        this.$notify({ type: 'error-bg', duration: 15000, text: 'Failed to load sound file' })
      }
    },
    _handlePlayingUI(e) {
      this.percentage = this.audioFile.currentTime / this.audioFile.duration * 100
      this.currentTime = formatTime(this.audioFile.currentTime)
    },
    _handlePlayPause(e) {
      if (e.type === 'pause' && !this.paused && !this.playing) {
        this.currentTime = '00:00:00'
      }
    },
    _handleEnded() {
      this.paused = this.playing = false
    },
    init() {
      this.audioFile.addEventListener('timeupdate', this._handlePlayingUI)
      this.audioFile.addEventListener('loadeddata', this._handleLoaded)
      this.audioFile.addEventListener('pause', this._handlePlayPause)
      this.audioFile.addEventListener('play', this._handlePlayPause)
      this.audioFile.addEventListener('ended', this._handleEnded)
    },
    closePlayer() {
      this.$emit('closePlayer')
    },
  },
  watch: {
    file: {
      handler() {
        this.stop()
      },
    },
  },
  beforeDestroy() {
    this.audioFile.removeEventListener('timeupdate', this._handlePlayingUI)
    this.audioFile.removeEventListener('loadeddata', this._handleLoaded)
    this.audioFile.removeEventListener('pause', this._handlePlayPause)
    this.audioFile.removeEventListener('play', this._handlePlayPause)
    this.audioFile.removeEventListener('ended', this._handleEnded)
  },
}
</script>
